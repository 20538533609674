/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./LandingHeader.module.scss";
import unileverLogo from "../../../assets/images/unileverLogo.svg";
import hamMenu from "../../../assets/images/hamMenu.svg";
import crossMenu from "../../../assets/images/crossMenu.svg";
import ucubeGraph from "../../../assets/images/ucubeGraph_blue.svg";
import logoutBlue from "../../../assets/images/logout_blue.svg";
import userManagement from "../../../assets/images/userManage_blue.svg";
import home from "../../../assets/images/Home.svg";
import dropdownArrow from "../../../assets/images/Dropdown_Arrow.svg";
import uBar from "../../../assets/images/u_graph-bar.svg";
import gold from "../../../assets/images/gold.svg";
import { getCookie, deleteCookie } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../ContextStore/StoreContextProvider";
import MuleFetchUtil from "../../../common/mule-fetch-util";
import Indonesia from "../../../assets/images/Indonesia.svg";
import Vietnam from "../../../assets/images/vietnam.svg";
import Thailand from "../../../assets/images/Thailand.svg";
import Philippines from "../../../assets/images/Phillipines.svg";
import Pakistan from "../../../assets/images/Pakistan.svg";
import MicroserviceFetchUtil from "../../../common/microservice-fetch-util";
import { isMobile } from "react-device-detect";
import ProfileIcon from "../../../assets/images/profileIcon.svg";
import { Tooltip } from "react-tooltip";
import OperationalReportsLogo from "../../../assets/images/OperationalReportsLogo.svg";
import ReportsAnalyticsLogo from "../../../assets/images/ReportingAndAnalyticsLogo.svg";
import UcubeAI from "../../../assets/images/Frame.svg";
import arrowLeft from "../../../assets/images/arrowLeft.svg";
import opDashboard from "../../../assets/images/opDashboard_blue.svg";
import blueArrow from "../../../assets/images/rightArrowBlue.svg";
import AdminConfigLogo from "../../../assets/images/AdminConfig.svg";
import AddSalesPerLogo from "../../../assets/images/addSalesperson.svg";
import PlusIcon from "../../../assets/images/plusIcon.svg";
import Modal from "../../UserAccessManagement/Modal/Modal";
import { doSSOLogout } from "../../../firebase/ssoAuthenticate";
import Select from "react-select";
import configData from "../../../common/configData.json";
import GaEventHelper from '../../../helper/ga-event-helper';
import { GA_EVENTS, GA_DATA_PARAMS } from '../../../common/gaEventConstants';

const LandingHeader = () => {
  const { userDetails, selectedCountryCode, updateCountryCode, selectedBsa } =
    useContext(StoreContext);
  const [dtAccess] = useState(
    userDetails.dtAccessCodes ? userDetails.dtAccessCodes.split(",") : []
  );
  const [superUser] = useState(
    userDetails.accessToOperationalDashboards &&
      (userDetails.isSuperUser === "YES" || !dtAccess.length)
  );
  const [dtAccessToggle, setDTAccessToggle] = useState("All");
  const selectedApp = localStorage.getItem("selectedApp");
  const [menu, setMenu] = useState(false);
  const [reports, setReports] = useState(false);
  const history = useHistory();
  const filteredCountries = [];
  const handleMenu = () => {
    setMenu(!menu);
  };
  const wrapperRef = useRef(null);

  const [operationalReportsData, setOperationalReportsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openReportDropdown, setOpenReportDropdown] = useState("");
  const [adminDropdown, setAdminDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [userType, setUserType] = useState("");
  const [userListUpdate, setUserListUpdate] = useState(false);
  const reportTypes = [
    {
      label: "Transactional Reports",
      value: "transactionalreports",
      icon: uBar,
    },
    { label: "Master Reports", value: "masterreports", icon: gold },
  ];

  console.log(userListUpdate);

  const emailId = userDetails && userDetails.email;
  const isIndonesia = localStorage.getItem("isIndonesia");
  const isVietnam = localStorage.getItem("isVietnam");
  const isPhilipines = localStorage.getItem("isPhilipines");
  const isThailand = localStorage.getItem("isThailand");
  const isPakistan = localStorage.getItem("isPakistan");
  const isIndonesiaIc = localStorage.getItem("isIndonesiaIc");
  const isHPC = configData?.common?.id?.isHPC;

  const countries = [
    {
      label: (
        <div className={styles.countryName}>
          <img src={Indonesia} className={styles.countryLogo} alt="" />
          {isHPC ? <p className={styles.nameOption}>{"Indonesia HPC"}</p> : <p className={styles.nameOption}>{"Indonesia"}</p>}
        </div>
      ),
      value: "id",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Indonesia} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Indonesia IC"}</p>
        </div>
      ),
      value: "idic",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Vietnam} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Vietnam"}</p>
        </div>
      ),
      value: "vn",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Philippines} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Philippines"}</p>
        </div>
      ),
      value: "ph",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Thailand} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Thailand"}</p>
        </div>
      ),
      value: "th",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Pakistan} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Pakistan"}</p>
        </div>
      ),
      value: "pk",
    },
    {
      label: (
        <div className={styles.countryName}>
          <img src={Pakistan} className={styles.countryLogo} alt="" />
          <p className={styles.nameOption}>{"Indonesia IC"}</p>
        </div>
      ),
      value: "idic",
    },
  ];

  useEffect(() => {
    if (filteredCountries && filteredCountries.length === 0) {
      if (isIndonesia) {
        filteredCountries.push(
          countries.find((country) => country.value === "id")
        );
      }

      if (isIndonesiaIc) {
        filteredCountries.push(
          countries.find((country) => country.value === "idic")
        );
      }

      if (isVietnam) {
        filteredCountries.push(
          countries.find((country) => country.value === "vn")
        );
      }

      if (isPhilipines) {
        filteredCountries.push(
          countries.find((country) => country.value === "ph")
        );
      }

      if (isThailand) {
        filteredCountries.push(
          countries.find((country) => country.value === "th")
        );
      }

      if (isPakistan) {
        filteredCountries.push(
          countries.find((country) => country.value === "pk")
        );
      }
    }
  }, [countries]);

  console.log("filtered", selectedCountryCode, isIndonesia);

  const logoutHandler = async () => {
    GaEventHelper.gaCommonEvent({userDetails: userDetails, screen_name:history?.location?.pathname?.replace('/',''),event: GA_EVENTS.logout })
    if (getCookie("isSSOLogin") || getCookie("isMFALogin")) {
      await doSSOLogout();
      getCookie("idToken") && deleteCookie("idToken");
      getCookie("refreshToken") && deleteCookie("refreshToken");
      getCookie("isSSOLogin") && deleteCookie("isSSOLogin");
      getCookie("isMFALogin") && deleteCookie("isMFALogin");
      getCookie("countryCode") && deleteCookie("countryCode")
      localStorage.removeItem("countryCode");
      localStorage.removeItem("isIndonesia");
      localStorage.removeItem("isIndonesiaIc");
      localStorage.removeItem("isVietnam");
      localStorage.removeItem("isPhilipines");
      localStorage.removeItem("isThailand");
      localStorage.removeItem("isPakistan");
      localStorage.removeItem("adminAccess");
      history.push("/login");
    } else {
      let email = emailId && emailId.toLowerCase();
      let obj = {
        email: email,
      };

      if (getCookie("idToken") !== null) {
        MuleFetchUtil.post(
          "fb/accounts/logout",
          { countryCode: selectedCountryCode },
          obj,
          "logout"
        )
          .then((response) => {
            if (
              response.statusCode === 200 &&
              response.statusMessage === "SUCCESS"
            ) {
              deleteCookie("idToken");
              if (getCookie("refreshToken") !== null) {
                deleteCookie("refreshToken");
              }
              history.push("/login");
            } else {
              console.log("Logout unsuccessful:", response);
            }
          })
          .catch((error) => {
            console.log("Error logging out:", error);
          });
      }
    }
  };

  useEffect(() => {
    if (
      history &&
      history.location &&
      history.location.pathname === "/operationalreportslandingpage"
    ) {
      localStorage.setItem("selectedApp", "operational");
    } else if (
      history &&
      history.location &&
      history.location.pathname === "/homepage"
    ) {
      localStorage.setItem("selectedApp", "ucube");
    } else if (
      history &&
      history.location &&
      history.location.pathname === "/useraccess"
    ) {
      localStorage.setItem("selectedApp", "useraccess");
    }
  }, [history]);

  const getOperationalReportData = async () => {
    setLoading(true);
    const getIdToken = getCookie("idToken");
    if (selectedCountryCode) {
      const result = await MicroserviceFetchUtil.get(
        "ucube/operational-kpis/operationalReportsList",
        { customerToken: getIdToken, countryCode: selectedCountryCode }
      );
      setLoading(false);
      console.log("resulttttttt", result);
      if (
        result &&
        result.statusCode === 200 &&
        result.statusMessage === "SUCCESS"
      ) {
        const opData = result["response-data"].operationalReportsList;
        if (opData.length > 0) {
          setOperationalReportsData(opData);
        } else {
          console.log("no data");
        }
      } else {
        console.log("Error", result);
      }
    }
  };

  useEffect(() => {
    (history.location.pathname === "/operationalreportslandingpage" ||
      history.location.pathname === "/reportpage") &&
      operationalReportsData &&
      !operationalReportsData.length &&
      getOperationalReportData();
    // window.ResizeObserver = null;
  }, [history.location.pathname]);

  const onUcubeClick = () => {
    localStorage.setItem("selectedApp", "ucube");
    const location = {
      pathname: "/homepage",
    };
    history.replace(location);
    history.push({ state: { ucube: true } });
  };

  const onBackButtonClick = (clickedOn) => {
    GaEventHelper.gaCommonEvent({userDetails: userDetails, screen_name:history?.location?.pathname?.replace('/',''),event: clickedOn === "homeIcon"? GA_EVENTS.select_home_icon:GA_EVENTS.go_back_navigation});
    localStorage.setItem("selectedApp", "ucube");
    const location = {
      pathname: "/landingscreen",
    };
    history.replace(location);
    history.push({ state: { ucube: true } });
  };

  const onAdminConfigurationClick = () => {
    localStorage.setItem("selectedApp", "ucube");
    const location = {
      pathname: "/adminconfigurationpage",
    };
    setMenu(false);
    history.replace(location);
  };

  const onOperationalDashboardsClick = () => {
    //localStorage.setItem("selectedApp", "operational");
    //setReports(true);
    const location = {
      pathname: "/landingscreen",
    };
    history.replace(location);
    history.push({ state: { ucube: false } });
    setMenu(!menu);
  };

  const handleDropdownChange = (report) => {
    GaEventHelper.gaSelectReportEvent({userDetails: userDetails, screen_name:history?.location?.pathname?.replace('/','') ,event: report.reportCategory === "transactionalreports"? GA_EVENTS.select_transactional_report: GA_EVENTS.select_master_reports, reportType: report.reportCategory === "transactionalreports"? GA_DATA_PARAMS.transactional_report_name:GA_DATA_PARAMS.master_report_name, reportName:report.reportName });
    history.replace({
      pathname: "/reportpage",
    });
    history.push({
      state: {
        reportName: report.reportName,
        reportCategory: report.reportCategory,
      },
    });
    setMenu(false);
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (
      history.location.pathname === "/operationalreportslandingpage" ||
      history.location.pathname === "/reportpage"
    ) {
      setReports(true);
    } else {
      setReports(false);
    }
  }, [history.location.pathname]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenReportDropdown("");
    }
  };
  const handleAdminConfigClick = () => {
    setAdminDropdown(!adminDropdown);
  };

  const onUsermanagementClick = () => {
    localStorage.setItem("selectedApp", "useraccess");
    const location = {
      pathname: "/useraccess",
    };
    history.replace(location);
    setMenu(!menu);
    setAdminDropdown(false);
  };

  const onTaskManagementClick = () => {
    localStorage.setItem("selectedApp", "taskmanagement");
    const location = {
      pathname: "/taskmanagment",
    };
    history.replace(location);
    setMenu(!menu);
    setAdminDropdown(false);
  };

  const onBestPriceEstimateClick = () => {
    localStorage.setItem("selectedApp", "estimatedbestprice");
    const location = {
      pathname: "/estimatedbestprice",
    };
    history.replace(location);
    setMenu(!menu);
    setAdminDropdown(false);
  };

  const onOutletStatusClick = () => {
    localStorage.setItem("selectedApp", "outletStatus");
    const location = {
      pathname: "/outletStatus",
    };
    history.replace(location);
    setMenu(!menu);
    setAdminDropdown(false);
  };

  const onDeviceGeoComplianceClick = () => {
    localStorage.setItem("selectedApp", "devicegeocompliance");
    const location = {
      pathname: "/devicegeocompliance",
    };
    history.replace(location);
    setMenu(!menu);
    setAdminDropdown(false);
  };

  const onAddSalesPersonClick = () => {
    setShowModal(true);
    setModalTitle("Add Salesman BG Mapping");
    setUserType("salesman");
  };

  const shouldRenderAdminConfigLink = () => {
    return (
      !isMobile &&
      ["/operationalreportslandingpage", "/reportpage"].every(
        (route) => route !== history.location.pathname
      ) &&
      userDetails &&
      userDetails.userType === "admin"
    );
  };

  const changeCountryCodeHandler = (event) => {
    updateCountryCode(event.value);
    localStorage.setItem("countryCode", event.value);
  };

  const displayDTAccess = (dtData) => {
    const data = [...dtData];
    if (
      (userDetails.isSuperUser === "YES" || userDetails.isSuperUser === "NO") &&
      !dtAccess?.length
    )
      return "All";
    if (data.includes("All (Super Access)")) return "All";
    if (dtAccessToggle === "Hide" || data?.length <= 3) return data?.join(", ");
    return `${data?.slice(0, 3)?.join(", ")}...`;
  };
  console.log("filteredCountries", filteredCountries);
  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.header}>
          <div className={styles.headertop}>
            <div className={styles.dttReports}>
              {history &&
                history.location &&
                (history.location.pathname ===
                  "/operationalreportslandingpage" ||
                  history.location.pathname === "/reportpage") && (
                  <img
                    src={arrowLeft}
                    onClick={()=>onBackButtonClick("backArrow")}
                    className={styles.backArrow}
                    alt="backArrow"
                  />
                )}
                <img src={UcubeAI} alt="" />
            </div>
            <div className={menu ? styles.menuSide : styles.navlinks}>
              {userDetails &&
                userDetails.accessToOperationalDashboards === "YES" && (
                  <div
                    onClick={onOperationalDashboardsClick}
                    className={styles.sideMenuOptions}
                  >
                    <img
                      src={opDashboard}
                      className={styles.menuIcons}
                      alt=""
                    />
                    <p> Reports & Analytics Home</p>
                  </div>
                )}
              {history.location.pathname !== "/operationalreportslandingpage" &&
                history.location.pathname !== "/reportpage" && (
                  <>
                    {userDetails &&
                      userDetails.accessToUcube === "YES" &&
                      userDetails.role !== null && (
                        <div
                          onClick={onUcubeClick}
                          className={styles.sideMenuOptions}
                        >
                          <img
                            src={ucubeGraph}
                            className={styles.menuIcons}
                            alt=""
                          />
                          <p> UCube</p>
                        </div>
                      )}

                    {/* {userDetails && userDetails.userType === "admin" && (
                      <div onClick={onUsermanagementClick} className={styles.userHeaderMbl}>
                        <img
                          src={userManagement} 
                          className={styles.menuIcons}
                          alt=""
                        />
                        <p
                          className={
                            selectedApp === "useraccess" ? styles.underLine : ""
                          }
                        >
                          {" "}
                          User management
                        </p>
                      </div>
                    )} */}
                  </>
                )}
              {isMobile &&
                reportTypes &&
                !loading &&
                reports &&
                reportTypes.map((reportType, index) => {
                  return superUser ||
                    reportType.value === "transactionalreports" ? (
                    <div
                      key={index}
                      onClick={() =>
                        setOpenReportDropdown(
                          openReportDropdown === reportType.value
                            ? ""
                            : reportType.value
                        )
                      }
                      className={
                        openReportDropdown === reportType.value
                          ? `${styles.dropdownMobile} ${styles.dropdownselected}`
                          : styles.dropdownMobile
                      }
                    >
                      <div className={styles.dropdownHeading}>
                        <div className={styles.dropdownHeadingName}>
                          <span>
                            <img
                              src={reportType.icon}
                              className={styles.dropdownIcon}
                              alt=""
                            />
                            {reportType.label}{" "}
                          </span>
                        </div>
                        <div className={styles.dropdownHeadingArrow}>
                          <img
                            src={blueArrow}
                            color="white"
                            className={
                              openReportDropdown === reportType.value
                                ? styles.dropdownArrowOpen
                                : styles.dropdownArrowClose
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      {openReportDropdown === reportType.value && (
                        <div className={styles.dropdownBody}>
                          {operationalReportsData
                            .filter(
                              (report) =>
                                report.reportCategory === reportType.value
                            )
                            .map((report, index) => (
                              <div
                                className={
                                  history.location.state?.reportName ===
                                  report.reportName
                                    ? `${styles.dropdownOptions} ${styles.dropdownSelected}`
                                    : styles.dropdownOptions
                                }
                                onClick={() => handleDropdownChange(report)}
                              >
                                <div className={styles["reportColumn__name"]}>
                                  {report.reportName}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
              {isMobile && userDetails && userDetails.userType === "admin" && (
                <>
                  <div className={styles.SMOMobileView}>
                    <div
                      className={styles.adminDropdown}
                      onClick={handleAdminConfigClick}
                    >
                      <img src={AdminConfigLogo} alt="AdminLogo" />
                      <p>Admin configuration</p>
                      <img
                        className={adminDropdown && styles.invertArrow}
                        src={blueArrow}
                        alt="dropdown"
                      />
                    </div>
                  </div>
                  {adminDropdown && (
                    <div className={styles.adminDropdownOptions}>
                      <p onClick={onUsermanagementClick}>
                        User Access Management
                      </p>
                      <p onClick={onTaskManagementClick}>Task Management</p>
                      {selectedCountryCode !== "id" && <p onClick={onBestPriceEstimateClick}>
                        Estimated Best Price
                      </p>}
                      <p onClick={onDeviceGeoComplianceClick}>
                        Device Geo-Compliance
                      </p>
                      <p onClick={onOutletStatusClick}>
                        Outlet Status - Online / Offline
                      </p>
                    </div>
                  )}
                  {selectedCountryCode !== "id" && <div
                    className={`${styles.SMOMobileView} ${styles.adminDropdown}`}
                    onClick={onAddSalesPersonClick}
                  >
                    <img src={AddSalesPerLogo} alt="addsalesperson" />
                    <p>Add SalesPerson Info</p>
                    <img src={PlusIcon} alt="plusicon" />
                  </div>}
                </>
              )}
              {!isMobile &&
                userDetails.isSuperUser !== "YES" &&
                userDetails.role !== "Super User" && (
                  <div className={styles.countryName}>
                    <img
                      src={
                        selectedCountryCode === "id"
                          ? Indonesia
                          :selectedCountryCode === "idic"
                          ? Indonesia
                          : selectedCountryCode === "vn"
                          ? Vietnam
                          : selectedCountryCode === "ph"
                          ? Philippines
                          :selectedCountryCode === "pk"
                          ? Pakistan
                          : Thailand
                      }
                      className={styles.countryLogo}
                      alt=""
                    />
                    <p className={styles.name}>
                      {selectedCountryCode === "id" && isHPC
                        ? "Indonesia HPC"
                        :selectedCountryCode === "id" && !isHPC
                        ? "Indonesia"
                        :selectedCountryCode === "idic"
                        ? "Indonesia IC"
                        : selectedCountryCode === "vn"
                        ? "Vietnam"
                        : selectedCountryCode === "ph"
                        ? "Philippines"
                        : selectedCountryCode === "pk"
                        ? "Pakistan"
                        : "Thailand "}
                    </p>
                  </div>
                )}
              {!isMobile &&
                userDetails.isSuperUser === "YES" &&
                userDetails.role === "Super User" && (
                  <Select
                    options={filteredCountries}
                    onChange={changeCountryCodeHandler}
                    isSearchable={false}
                    classNames={{
                      container: () => styles.dropdownCountry,
                      control: () => styles.dropdownControl,
                      valueContainer: () => styles.dropdownControl,
                      indicatorsContainer: () => styles.dropdownArrow,
                      singleValue: () => styles.dropdownText,
                      placeholder: () => styles.dropdownText,
                      indicatorSeparator: () => styles.seperator,
                      option: () => styles.menuOption,
                      menu: () => styles.menu,
                      menuList: () => styles.menuList,
                    }}
                    defaultValue={{
                      label: (
                        <div className={styles.countryName}>
                          <img
                            src={
                              selectedCountryCode === "id"
                                ? Indonesia
                                :selectedCountryCode === "idic"
                                ? Indonesia
                                : selectedCountryCode === "vn"
                                ? Vietnam
                                : selectedCountryCode === "ph"
                                ? Philippines
                                :selectedCountryCode === "pk"
                                ? Pakistan
                                : Thailand
                            }
                            className={styles.countryLogo}
                            alt=""
                          />
                          <p className={styles.name}>
                            {selectedCountryCode === "id" && !isHPC
                              ? "Indonesia"
                              :selectedCountryCode === "id" && isHPC
                              ? "Indonesia HPC"
                              :selectedCountryCode === "idic"
                              ? "Indonesia IC"
                              : selectedCountryCode === "vn"
                              ? "Vietnam"
                              : selectedCountryCode === "ph"
                              ? "Philippines"
                              :selectedCountryCode === "pk"
                              ? "Pakistan"
                              : "Thailand "}
                          </p>
                        </div>
                      ),
                      value: selectedCountryCode,
                    }}
                    value={{
                      label: (
                        <div className={styles.countryName}>
                          <img
                            src={
                              selectedCountryCode === "id"
                                ? Indonesia
                                :selectedCountryCode === "idic"
                                ? Indonesia
                                : selectedCountryCode === "vn"
                                ? Vietnam
                                : selectedCountryCode === "ph"
                                ? Philippines
                                : selectedCountryCode === "pk"
                                ? Pakistan
                                : Thailand
                            }
                            className={styles.countryLogo}
                            alt=""
                          />
                          <p className={styles.name}>
                            {selectedCountryCode === "id" && !isHPC
                              ? "Indonesia"
                              :selectedCountryCode === "id" && isHPC
                              ? "Indonesia HPC"
                              :selectedCountryCode === "idic"
                              ? "Indonesia IC"
                              : selectedCountryCode === "vn"
                              ? "Vietnam"
                              : selectedCountryCode === "ph"
                              ? "Philippines"
                              : selectedCountryCode === "pk"
                              ? "Pakistan"
                              : "Thailand "}
                          </p>
                        </div>
                      ),
                      value: selectedCountryCode,
                    }}
                  />
                )}
              {isMobile && (
                <div className={styles.SMOMobileView} onClick={logoutHandler}>
                  <img
                    src={logoutBlue}
                    className={styles.menuIcons}
                    alt="Logout"
                  />
                  <p>Logout</p>
                </div>
              )}
              <div className={styles.profileIcon} onClick={()=> {
                GaEventHelper.gaProfileEvent({userDetails: userDetails, screen_name:history?.location?.pathname?.replace('/',''),event: GA_EVENTS.Profile_view,selectedBsa:selectedBsa})
              }}>
                <img
                  src={ProfileIcon}
                  alt="Profileicon"
                  data-tooltip-id="profilePopup"
                />
              </div>
              <Tooltip
                className={styles.popupModal}
                id="profilePopup"
                place="bottom"
                openOnClick="true"
                offset="8"
                noArrow
                clickable
              >
                <div className={styles.popup}>
                  <div className={styles.popupDetails}>
                    <h4>{userDetails.name}</h4>
                    <p>{userDetails.email}</p>
                    {userDetails.accessToOperationalDashboards === "YES" && (
                      <div className={styles.dtAccess}>
                        {" "}
                        <p className={styles.title}>DT Access: </p>
                        <p className={styles.display}>
                          {displayDTAccess(dtAccess)}
                        </p>
                        <p
                          className={styles.toggle}
                          onClick={() => {
                            setDTAccessToggle((prev) =>
                              prev === "All" ? "Hide" : "All"
                            );
                          }}
                        >
                          {dtAccess.length > 3 && dtAccessToggle}
                        </p>
                      </div>
                    )}
                  </div>
                  <div onClick={logoutHandler} className={styles.popupLogout}>
                    <img src={logoutBlue} alt="" />
                    <p>Logout</p>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={menu ? styles.headerSidebar : styles.headerbottom}>
          {/* {!isMobile && (history.location.pathname !== "/operationalreportslandingpage" && history.location.pathname !== "/reportpage") && userDetails && userDetails.userType === "admin" && ( */}
          {shouldRenderAdminConfigLink() && (
            <div
              className={styles.userHeaderDesktop}
              onClick={onAdminConfigurationClick}
            >
              <div>
                <img src={userManagement} className={styles.menuIcons} alt="" />
                <p
                  className={
                    selectedApp === "useraccess" ? styles.underLine : ""
                  }
                >
                  {" "}
                  Admin configuration
                </p>
              </div>
            </div>
          )}
          {!isMobile && (
            <div className={styles.dropdownConatiner}>
              {history &&
              history.location &&
              (history.location.pathname === "/operationalreportslandingpage" ||
                history.location.pathname === "/reportpage") ? (
                <div
                  onClick={()=>onBackButtonClick("homeIcon")}
                  className={
                    history &&
                    history.location.state &&
                    history.location.state.reportName
                      ? styles.backButton
                      : `${styles.backButton} ${styles.navLinkSelected}`
                  }
                >
                  <img src={ucubeGraph} className={styles.menuIcons} alt="" />{" "}
                  <img src={home} className={styles.backArrow} alt="" />
                </div>
              ) : (
                <></>
              )}
              <div className={styles.dropdownWrapper} ref={wrapperRef}>
                {reportTypes &&
                  (history.location.pathname ===
                    "/operationalreportslandingpage" ||
                    history.location.pathname === "/reportpage") &&
                  !loading &&
                  reportTypes.map((reportType, index) => {
                    return superUser ||
                      reportType.value === "transactionalreports" ? (
                      <div
                        key={index}
                        onClick={() =>
                          setOpenReportDropdown(
                            openReportDropdown === reportType.value
                              ? ""
                              : reportType.value
                          )
                        }
                        className={
                          openReportDropdown === reportType.value
                            ? `${styles.dropdown} ${styles.dropdownselected}`
                            : history &&
                              history.location &&
                              history.location.state &&
                              history.location.state.reportName &&
                              history.location.state.reportCategory ===
                                reportType.value
                            ? `${styles.dropdown} ${styles.navLinkSelected}`
                            : styles.dropdown
                        }
                      >
                        <span>
                          {reportType.label}{" "}
                          <img
                            src={dropdownArrow}
                            color="white"
                            className={styles.dropdownArrow}
                            alt=""
                          />
                        </span>
                        {openReportDropdown === reportType.value && (
                          <div className={styles.dropdownBody}>
                            {operationalReportsData
                              .filter(
                                (report) =>
                                  report.reportCategory === reportType.value
                              )
                              .map((report, index) => (
                                <div
                                  className={styles.dropdownOptions}
                                  onClick={() => handleDropdownChange(report)}
                                >
                                  <div className={styles["reportColumn__name"]}>
                                    {report.reportName}
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ) : null;
                  })}
              </div>
            </div>
          )}
          <div className={styles.hamMenu} onClick={handleMenu}>
            <img src={menu ? crossMenu : hamMenu} alt="menu" />
          </div>
          <div className={styles.username}>
            {userDetails && userDetails.name && (
              <p className={styles.userName}>Hello {userDetails.name}</p>
            )}
          </div>{" "}
          {isMobile && (
            <>
              {userDetails.isSuperUser !== "YES" &&
                userDetails.role !== "Super User" && (
                  <div className={styles.countryNameMbl}>
                    <img
                      src={
                        selectedCountryCode === "id"
                          ? Indonesia
                          :selectedCountryCode === "idic"
                          ? Indonesia
                          : selectedCountryCode === "vn"
                          ? Vietnam
                          : selectedCountryCode === "ph"
                          ? Philippines
                          : selectedCountryCode === "pk"
                          ? Pakistan
                          : Thailand
                      }
                      className={styles.countryLogo}
                      alt=""
                    />
                  </div>
                )}
              {userDetails.isSuperUser === "YES" &&
                userDetails.role === "Super User" && (
                  <div className={styles.countryNameMbl}>
                    <Select
                      options={filteredCountries}
                      onChange={changeCountryCodeHandler}
                      isSearchable={false}
                      classNames={{
                        container: () => styles.dropdownCountry,
                        control: () => styles.dropdownControl,
                        valueContainer: () => styles.dropdownControl,
                        indicatorsContainer: () => styles.dropdownArrow,
                        singleValue: () => styles.dropdownText,
                        placeholder: () => styles.dropdownText,
                        indicatorSeparator: () => styles.seperator,
                        option: () => styles.menuOption,
                        menu: () => styles.menu,
                        menuList: () => styles.menuList,
                      }}
                      defaultValue={{
                        value:
                          selectedCountryCode === "id"
                            ? Indonesia
                            : selectedCountryCode === "idic"
                            ? Indonesia
                            : selectedCountryCode === "vn"
                            ? Vietnam
                            : selectedCountryCode === "ph"
                            ? Philippines
                            : selectedCountryCode === "pk"
                            ? Pakistan
                            : Thailand,
                        label: (
                          <div className={styles.countryName}>
                            <img
                              src={
                                selectedCountryCode === "id"
                                  ? Indonesia
                                  : selectedCountryCode === "idic"
                                  ? Indonesia
                                  : selectedCountryCode === "vn"
                                  ? Vietnam
                                  : selectedCountryCode.value === "ph"
                                  ? Philippines
                                  : selectedCountryCode === "pk"
                                  ? Pakistan
                                  : Thailand
                              }
                              className={styles.countryLogo}
                              alt=""
                            />
                          </div>
                        ),
                      }}
                      value={{
                        value:
                          selectedCountryCode === "id"
                            ? Indonesia
                            : selectedCountryCode === "idic"
                            ? Indonesia
                            : selectedCountryCode === "vn"
                            ? Vietnam
                            : selectedCountryCode === "ph"
                            ? Philippines
                            : selectedCountryCode === "pk"
                            ? Pakistan
                            : Thailand,
                        label: (
                          <div className={styles.countryName}>
                            <img
                              src={
                                selectedCountryCode === "id"
                                  ? Indonesia
                                  : selectedCountryCode === "idic"
                                  ? Indonesia
                                  : selectedCountryCode === "vn"
                                  ? Vietnam
                                  : selectedCountryCode === "ph"
                                  ? Philippines
                                  : selectedCountryCode === "pk"
                                  ? Pakistan
                                  : Thailand
                              }
                              className={styles.countryLogo}
                              alt=""
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                )}
              {/* <div className={styles.SMOMobileView} onClick={logoutHandler}>
                    <img
                      src={logoutBlue}
                      className={styles.menuIcons}
                      alt="Logout"
                    />
                    <p>Logout</p>
                  </div> */}
            </>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={modalTitle}
          userType={userType}
          onClose={() => setShowModal(false)}
          setUserListUpdate={setUserListUpdate}
        />
      )}
    </>
  );
};

export default LandingHeader;
